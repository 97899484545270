import '../scss/Footer.scss';
import React, { useContext } from 'react';
import EpayLogo from '../assets/logo/EPAY-Logo-01 1.png'
import MegaVLogo from '../assets/logo/Group 1513.png'
import Prize from '../assets/images/prize.png'

import { displayTextByLanguage } from '../assets/languages/text'
import LanguageContext from '../contexts/LanguageContext';

export default function ({ }) {

    const { language } = useContext(LanguageContext)
    const displayText = displayTextByLanguage[language].footer;
    return (
        <div className="section footer">
            <div className="logo-container">
                <a target="_blank" href="https://vnptepay.com.vn/">
                    <img src={EpayLogo} alt="" className="logo" />

                </a>
                <a target="_blank" href='https://megav.vn/'>
                    <img src={MegaVLogo} alt="" className="logo" />
                </a>
            </div>
            <div className="content">

                <div className="left">

                    <div className="info-container">

                        <Office
                            title={displayText.office1.title}
                            address={displayText.office1.address}
                            phoneText={displayText.office1.phoneText}
                            phone={displayText.office1.phone}
                            faxText={displayText.office1.faxText}
                            fax={displayText.office1.fax}
                        />

                        <Office
                            title={displayText.office2.title}
                            address={displayText.office2.address}
                            phoneText={displayText.office2.phoneText}
                            phone={displayText.office2.phone}
                            faxText={displayText.office2.faxText}
                            fax={displayText.office2.fax}
                        />

                    </div>
                </div>

                <div className="right">
                        {/* <p className="hotline medium-text">Hotline: <a target="_blank" className="dark-red-text" href={`tel:${displayText.hotline}`}>{displayText.hotline}</a> </p> */}
                        {/* <a target="_blank" className="dark-red-text" href={ `tel:${displayText.hotline}`}></a> */}
                    <div className="hotline medium-text" dangerouslySetInnerHTML={{ __html: displayText.hotlineText_html }}></div>
                    <div className="hotline medium-text" dangerouslySetInnerHTML={{ __html: displayText.emailText_html }}></div>
                        {/* {
                            language == 'vn' ? <p className="hotline medium-text">{displayText.hotlineText} <br /> <a target="_blank" className='hotline-number' href={`tel:${displayText.hotline}`}>{displayText.hotline}</a></p>
                                :
                                <>
                                    <div className='contact-box'>
                                        <p className="hotline medium-text bold ">{displayText.hotlineText}</p>
                                        <div className="contact-container">

                                            {displayText.contacts.map((contact, index) => {
                                                return (
                                                    <Contact key={index} title={contact.title} phone={contact.phone} />
                                                )
                                            })}
                                        </div>
                                    </div>
                                </>

                        } */}

                    {/* <p className="hotline medium-text">{displayText.hotlineText} <br /> <a target="_blank" className='hotline-number' href={`tel:${displayText.hotline}`}>{displayText.hotline}</a></p> */}

                </div>
            </div>

            <hr />
            <div className="other-info">
                <p className="text">{displayText.businessLicense}</p>
                <img className='prize-image' src={Prize} alt="" />
            </div>
        </div>
    )
}


function Office({ title, address, phoneText, phone, faxText, fax }) {
    return (
        <div className="info">
            <p className="info-heading medium-text">{title}</p>
            <p className="info-address">{address}</p>
            <div className="info-contact-container">
                <p className="contact">{phoneText}: <a target="_blank" className="bold" href={`tel:${phone}`}>{phone}</a></p>
                <p className="contact">{faxText}: <a target="_blank" className="bold">{fax}</a></p>
            </div>
        </div>
    )
}

function Contact({ title, phone }) {
    return (
        <div className="Contact">
            <p className="contact-title">{title}</p>
            <a target="_blank" className="contact-phone" href={`tel:${phone}`} >{phone}</a>
        </div>
    )
}