import '../scss/Hero.scss';
import React, { useContext, useEffect, useState } from 'react';
import { displayTextByLanguage } from '../assets/languages/text'
import BackgroundImage from '../assets/images/hero_backgroud.svg'
import LanguageContext from '../contexts/LanguageContext'
import HeroHeadingTextVN from '../assets/images/hero_text_vn.png'
import HeroHeadingTextKR from '../assets/images/hero_text_kr.png'
import CheckedCircleWhite from '../assets/icon/checked_circle_white.svg'
import GirlWithPhoneVN from '../assets/images/girl_with_phone_vn.png'
import GirlWithPhoneKR from '../assets/images/girl_with_phone_kr.png'
import DownloadGoogle from '../assets/images/download-google.svg'
import DownloadApple from '../assets/images/download-apple.svg'

export default function ({ ref }) {
    const { language } = useContext(LanguageContext)

    const displayText = displayTextByLanguage[language].hero;

    const HeroHeadingText = language === 'vn' ? HeroHeadingTextVN : HeroHeadingTextKR;
    const GirlWithPhone = language === 'vn' ? GirlWithPhoneVN : GirlWithPhoneKR;


    // get the window width
    const [windowWidth, setWindowWidth] = useState(window.innerWidth)

    // update the window width when the window resizes
    useEffect(() => {
        window.addEventListener('resize', () => setWindowWidth(window.innerWidth))

        return () => {
            window.removeEventListener('resize', () => setWindowWidth(window.innerWidth))
        }
    }, [])


    // if the window width is less than 768px, return null
    if (windowWidth <= 768) {
        return (
            <div className="section hero-section mobile">
                <div className="background-image">
                    <img src={BackgroundImage} alt="" />
                </div>
                <div className="content">
                    <img src={HeroHeadingText} alt="" className="hero-heading-text" />
                    <div className="white-box">
                        <p className="text-in-white-box" dangerouslySetInnerHTML={{ __html: displayText.text_in_white_box_html }} />
                    </div>
                    <div className="image-container">
                        <img src={GirlWithPhone} alt="" />
                    </div>
                    <div className="item-container">
                        {displayText.text_items.map((text, index) => {
                            return <Item key={index} text={text} />
                        })}
                    </div>
                    <div className="vnpt-epay-btn">
                        <a href="#register-form">{displayText.button_contact_text}</a>
                        {/* <a target="_blank" href="https://forms.gle/iiyptmXPUFRCFv6T9"> */}
                            
                        {/* </a> */}
                    </div>
                    {/* <div className="download-app-btn">                            
                        <a target="_blank" href='https://apps.apple.com/vn/app/megav/id1611528414?l=vi'>
                            <img className='download' src={DownloadApple} alt="" />
                        </a>

                        <a target="_blank"  href='https://play.google.com/store/apps/details?id=com.epay.ewallet'>
                            <img className='download' src={DownloadGoogle} alt="" />
                        </a>    
                    </div> */}

                </div>
            </div>
        )
    }

    const marginTopStyle = language == 'kr' ? "negative-margin-top" : ""
    return (

        <section className='section hero-section' ref={ref}>
            <div className="background-image">
                <img src={BackgroundImage} alt="" />
            </div>
            <div className="content">
                <div className="left">
                    <img src={HeroHeadingText} alt="" className="hero-heading-text" />
                    <div className={`hero-text ${marginTopStyle}`}>
                        <div className="white-box">
                            <p className="text-in-white-box" dangerouslySetInnerHTML={{ __html: displayText.text_in_white_box_html }} />
                        </div>
                        <div className="item-container">
                            {displayText.text_items.map((text, index) => {
                                return <Item key={index} text={text} />
                            })}
                        </div>
                        <div className="vnpt-epay-btn">
                            <a href="#register-form">{displayText.button_contact_text}</a>
                            {/* <a target="_blank" href="https://forms.gle/iiyptmXPUFRCFv6T9"> */}
                                
                            {/* </a> */}
                        </div>
                        {/* <div className="download-app-btn">                            
                            <a target="_blank" href='https://apps.apple.com/vn/app/megav/id1611528414?l=vi'>
                                <img className='download' src={DownloadApple} alt="" />
                            </a>

                            <a target="_blank"  href='https://play.google.com/store/apps/details?id=com.epay.ewallet'>
                                <img className='download' src={DownloadGoogle} alt="" />
                            </a>    
                        </div> */}
                        
                    </div>


                </div>
                <div className="right">
                    <img src={GirlWithPhone} alt="" />
                </div>
            </div>


        </section>

    )
}

function Item({ text }) {
    return (
        <div className="Item">
            <img src={CheckedCircleWhite} alt="" className="item-icon" />
            <p className="item-text">{text}</p>
        </div>
    )
}