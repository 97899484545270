import '../scss/USP.scss'
import React, { useContext } from 'react';

import { displayTextByLanguage } from '../assets/languages/text'
import LanguageContext from '../contexts/LanguageContext'
import PhoneScreenVN from '../assets/images/phone_screen_vn.png'
import PhoneScreenKR from '../assets/images/phone_screen_kr.png'
import BlackDot from '../assets/icon/black_dot.svg'
import CheckedDotRed from '../assets/icon/checked_circle_red.svg'

export default function () {
    const { language } = useContext(LanguageContext)


    console.log('language', language);

    const displayText = displayTextByLanguage[language].USP;
    const PhoneScreen = language === 'vn' ? PhoneScreenVN : PhoneScreenKR;
    // USP stands for Unique Selling Point

    return <div className="section USP-section">
        <div className="content">
            <div className="section-heading italic">{displayText.section_heading}</div>
            <div className="grid-container">
                <div className="left">
                    <div className="heading">{displayText.store_heading}</div>
                    {displayText.store_items.map((item, index) => {
                        return (
                            <GridItem key={index} text={item}  language={language}/>
                        )
                    })}

                    <div className="vertical-line"></div>
                </div>
                <div className="right">
                    <div className="heading">{displayText.customer_heading}</div>
                    {displayText.customer_items.map((item, index) => {
                        return (
                            <GridItem key={index} text={item} language={language}/>
                        )
                    })}
                </div>
            </div>
            <div className="red-box">
                <div className="text-in-red-box-1">
                    {displayText.text_in_red_box_1}
                </div>
                <div className="text-in-red-box-2">
                    {displayText.text_in_red_box_2}
                </div>
                <img src={PhoneScreen} alt="" className="phone-screen" />
            </div>
            <div className="item-container">
                {displayText.text_items.map((text, index) => {
                    return <Item key={index} text={text} />
                })}
            </div>
        </div>

    </div>
}

function GridItem({ text, language }) {
    const style = language == 'kr' ? 'bold' : ""
    return (
        <div className='GridItem'>
            <img src={BlackDot} alt="" className="item-icon" />
            <p className={`item-text ${style}`}>{text}</p>
        </div>
    )
}

function Item({ text }) {
    return (
        <div className="Item">
            <img src={CheckedDotRed} alt="" className="item-icon" />
            <div className="item-text">{text}</div>
        </div>
    )
}