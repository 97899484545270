import './App.scss';
import React, { useState, useRef, useEffect } from 'react'
import Navbar from './sections/Navbar';
import Footer from './sections/Footer';
import Hero from './sections/Hero';
import RegistrationRequirements from './sections/RegistrationRequirements';
import Box from './sections/Pricing'
import LanguageContext from './contexts/LanguageContext'
import USP from './sections/USP';
import QnA from './sections/QnA';
import Register from './sections/Register';
import Pricing from './sections/Pricing';

function App() {
  const [language, setLanguage] = useState('vn')
  const ref = useRef(null);
  const scrollTo = (ref) => {
      if (ref && ref.current) {
          ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
      }
  }

  // get the language from local storage
  useEffect(() => {
    const language = localStorage.getItem('megav-language')
    const supportedLanguages = ['vn', 'kr']
    if (language &&supportedLanguages.includes(language.toLowerCase())) {
      setLanguage(language)
    }
  }, [])

  // save the language to local storage
  useEffect(() => {
    localStorage.setItem('megav-language', language)
  }, [language])



  // get the /?lang= query string
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const defaultLanguage = 'vn'
    if (!urlParams) {
      setLanguage(defaultLanguage)
    } else {
      const lang = urlParams.get('lang');
      const supportedLanguages = ['vn', 'kr']
      
      if (lang && supportedLanguages.includes(lang.toLowerCase())) {
        setLanguage(lang)
      } else {
        setLanguage(defaultLanguage)
      }
    }

  }, [])


  // set the query string when language changes
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    if (!urlParams) return
    urlParams.set('lang', language)
    window.history.replaceState({}, '', `${window.location.pathname}?${urlParams}`)
  }, [language])

  return (

    <LanguageContext.Provider value={{ language, setLanguage }}>

      <div className="App">
        <Navbar />

        <Hero />
        <USP />
        <RegistrationRequirements />
        <QnA />
        <Register />
        {/* <Pricing /> */}

        <Footer />
      </div>

    </LanguageContext.Provider>
  )
}

export default App