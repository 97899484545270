import '../scss/Register.scss'
import React, { useContext } from 'react';
import { useState } from 'react';
import validator from 'validator';
import emailjs from '@emailjs/browser';
import QR1 from '../assets/images/qr1.svg'
import QR2 from '../assets/images/qr2.svg'
import AppleStore from '../assets/images/download-apple.svg'
import GooglePlay from '../assets/images/download-google.svg'
import Background from '../assets/images/register_background.png';
import { displayTextByLanguage } from '../assets/languages/text';
import LanguageContext from '../contexts/LanguageContext'
import RegisterGirl from '../assets/images/register_girl.png'
export default function ({ ref }) {
    const { language } = useContext(LanguageContext)

    const displayText = displayTextByLanguage[language].register;
    
    const  initalContact = {
        name: '',
        phone: '',
        email: '',
        industry: ''
    }

    const initalErrors = {
        name: '',
        phone: '',
        email: '',
        industry: ''
    }
    const  [formData, setFormData] = useState(initalContact);
    const  [errors, setErrors] = useState({});
    const  [messageSuccess, setSuccess] = useState({
        'message': ''
    });
    const [isSubmitting, setOnSubmitting] = useState(false);
      
    const errorChangeHandler = (errors) => {
        setErrors((prevErrors) => ({...prevErrors, ...errors}));
    }
    const validateName= (value) => {
        let errors = {};
        if (validator.isEmpty(value)) {
            errors.name = displayText.validate.name;
        }else{
            errors.name = '';
        }
        errorChangeHandler(errors);
    }
    const validatePhone= (value) => {
        let errors = {};
        if (validator.isEmpty(value)) {
            errors.phone = displayText.validate.phone;
        }else{
            errors.phone = '';
        }
        errorChangeHandler(errors);
    }
    const resetForm = () => {
        setFormData(initalContact);
        setErrors({});
        setOnSubmitting(false);
    }
    const inputChangeHandle = (input, value) => {        
        if (input == 'name') {
            validateName(value);
        }
        if (input == 'phone') {
            validatePhone(value);
        }
        setFormData((prevInput) => {
            return {
                ...prevInput,
                [input]: value.trim(),
            };
        });
    }

    const submitHandler = (e) => {
        e.preventDefault();
        setOnSubmitting(true)
        validateName(formData.name);
        validatePhone(formData.phone);
        if(errors.name != '' || errors.phone != ''){
            setOnSubmitting(false);
            return false;
        }
        emailjs.send('service_dfgv6tl', 'template_uqm229i', formData, 'o9zxLv_0qaBIoC_C1')
        .then(function(response) {
            let var_success = {};
            var_success.message = displayText.success;
            setSuccess(var_success)
            resetForm();
            console.log('SUCCESS!', response.status, response.text);
            setTimeout(
                () => setSuccess({})
            , 3000)
        }, function(error) {
            console.log('FAILED...', error);
            setOnSubmitting(false);
        });
    }
    return (
        <div className="section register" ref={ref}>
            <div id="register-form"></div>
            <div className="content">
                <div className="grid-container">
                    <div className="image-container">            
                        <img src={Background} alt="" className="background-image" />
                        <img src={RegisterGirl} alt="" className='register-image-phone' />
                    </div>
                    <div className="item container">
                    <div className="section-heading">{displayText.sectionHeading}</div>
                    <form id="contact-form" onSubmit={submitHandler} method="POST">
                        <div className="form-group">
                            <input type="text" placeholder={displayText.title.name} className="form-control" id="name" value={formData.name} onChange={(e) => inputChangeHandle(
                                'name',
                                e.target.value
                            )} />
                            {errors && errors.name && <div className="validation" style={{display: 'block'}}>{errors.name}</div>}
                        </div>
                        <div className="form-group">
                            <input type="text" className="form-control" placeholder={displayText.title.phone} id="phone" value={formData.phone} onChange={(e) => inputChangeHandle(
                                'phone',
                                e.target.value
                            )} />
                            {errors && errors.phone && <div className="validation" style={{display: 'block'}}>{errors.phone}</div>}
                        </div>
                        <div className="form-group">
                            <input type="text" className="form-control" placeholder={displayText.title.email} id="email" value={formData.email} onChange={(e) => inputChangeHandle(
                                'email',
                                e.target.value
                            )} />
                            {errors && errors.email && <div className="validation" style={{display: 'block'}}>{errors.email}</div>}
                        </div>
                        <div className="form-group">
                            {/* <input type="text" className="form-control" placeholder={displayText.title.industry} id="industry" value={formData.industry} onChange={(e) => inputChangeHandle(
                                'industry',
                                e.target.value
                            )} /> */}
                            <select className="form-control" placeholder={displayText.title.industry} id="industry" value={formData.industry} onChange={(e) => inputChangeHandle(
                                'industry',
                                e.target.value
                            )}>
                            {displayText.industry.map((item, index) => {
                                return <option className='price-text' value={item}>{item}</option>
                            })}
                            </select>
                        </div>
                        {messageSuccess && messageSuccess.message && <div className="success" style={{display: 'block'}}>{messageSuccess.message}</div>}
                        <button type="submit" className="button-contact" disabled = {isSubmitting}>{displayText.button}</button>
                    </form>
                    </div>
                </div>
            </div>
            
            <div className="qr-code-container">
                <a target="_blank" className="qr-code" href='https://apps.apple.com/vn/app/megav/id1611528414?l=vi'>
                    <img src={QR1} className="code" />
                    <img src={AppleStore} alt="" className="download" />
                </a>
                <a target="_blank" className="qr-code" href='https://play.google.com/store/apps/details?id=com.epay.ewallet'>
                    <img src={QR2} className="code" />
                    <img src={GooglePlay} alt="" className="download" />
                </a>
            </div>
        </div>
    );
}
