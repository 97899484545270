import '../scss/QnA.scss'
import React, { useContext } from 'react';

import { displayTextByLanguage } from '../assets/languages/text'
import LanguageContext from '../contexts/LanguageContext'
import MinusCircle from '../assets/icon/minus_circle.svg'
import ItemIcon1 from '../assets/images/receipt.png'
import ItemIcon2 from '../assets/images/withdrawal.png'

import QR1 from '../assets/images/qr1.svg'
import QR2 from '../assets/images/qr2.svg'
import AppleStore from '../assets/images/download-apple.svg'
import GooglePlay from '../assets/images/download-google.svg'
export default function () {
    const { language } = useContext(LanguageContext)
    const displayText = displayTextByLanguage[language].QnA;
    // USP stands for Unique Selling Point
    return <div className="section QnA-section">

        <div className="content">
            <div className="section-heading">{displayText.sectionHeading}</div>
            <div className="question-container">
                {displayText.questions.map((question, index) => {
                    return (
                        <Question index={index} question={question} />
                    )
                })}
            </div>

            <div className="item-container">
                {
                    displayText.items.map((item, index) => {
                        return <Item
                            key={index}
                            icon={index == 0 ? ItemIcon1 : ItemIcon2}
                            title={item.title}
                            text_items={item.text_items}
                            language={language}
                        />
                    })
                }
            </div>

        </div>
    </div>
}

function Question({ question }) {
    return (
        <div className="Question">
            <div className="question-title">
                <img className="question-icon" src={MinusCircle} />
                <div className="question-text" dangerouslySetInnerHTML={{__html: question.html_title}}></div>
            </div>
            <ul className="answer-container">
                {question.answers.map((answer, index) => {
                    return (
                        <li className="answer-text">{answer}</li>
                    )
                })}
            </ul>
        </div>
    )
}


function Item({ icon, title, text_items , language}) {
    const style = language == 'kr' ? 'bold' : ""
    return (
        <div className="Item">
            <img src={icon} alt="" className="item-icon" />
            <p className="item-title">{title}</p>
            <ul className="text-container">
                {text_items.map((text, index) => {
                    return (
                        <li className={`text-item ${style}`}>{text}</li>
                    )
                })}
            </ul>
        </div>
    )
}