import '../scss/RegistrationRequirements.scss'
import React, { useContext } from 'react';
import Background from '../assets/images/registration_requirement_background.png';
import { displayTextByLanguage } from '../assets/languages/text';
import LanguageContext from '../contexts/LanguageContext'
import ContactIcon from '../assets/icon/contract.svg'
import IdCardIcon from '../assets/icon/id_card.svg'
import IphoneIcon from '../assets/icon/iphone.svg'
import CreditCardIcon from '../assets/icon/credit_card.svg'
import DocumentImage from '../assets/images/document.png'
export default function () {
    const { language } = useContext(LanguageContext)

    const displayText = displayTextByLanguage[language].registrationRequirements;
    const itemIcons = [ContactIcon, IdCardIcon, IphoneIcon, CreditCardIcon]
    return (
        <div className="section registration-requirements">
            <div className="content">
            
                <img src={Background} alt="" className="background-image" />
                <div className="section-heading">{displayText.sectionHeading}</div>
                <div className="grid-container">
                    <div className="item container">
                        {displayText.text_items.map((text, index) => {
                            return (
                                <Item icon={itemIcons[index]} text={text} />
                            )
                        })}
                    </div>
                    <div className="image-container">
                        <img src={DocumentImage} alt="" />
                    </div>
                </div>

            </div>
        </div>
    )
}

function Item({ icon, text }) {
    return (
        <div className="Item">
            <img src={icon} alt="" className="item-icon" />
            <p className="item-text">{text}</p>
        </div>
    )

}