import '../scss/Navbar.scss';
import React, { useState, useEffect, useRef, useContext } from 'react';
import MegaVLogo from '../assets/images/megav_logo.svg'
import VNPTLogo from '../assets/images/vnptepay_logo.svg'
import { displayTextByLanguage } from '../assets/languages/text'
import VietnameFlag from '../assets/images/vietnam.png'
import KoreanFlag from '../assets/images/south-korea 2.png'
import LanguageContext from '../contexts/LanguageContext'
import Arrow from '../assets/icon/arrow.svg'
import DownloadGoogle from '../assets/images/download-google.svg'
import DownloadApple from '../assets/images/download-apple.svg'



export default function ({ }) {
    const { language, setLanguage } = useContext(LanguageContext)

    // const displayText = displayTextByLanguage[language].navbar;
    const flags = [
        {
            flag: VietnameFlag,
            language: 'vn'
        }, {
            flag: KoreanFlag,
            language: 'kr'
        }]


    const [currentFlag, setCurrentFlag] = useState({
        flag: VietnameFlag,
        language: 'vn'
    })
    const [otherFlag, setOtherFlag] = useState(
        {
            flag: KoreanFlag,
            language: 'kr'
        }
    )

    useEffect(() => {
        if (language === 'vn') {
            setCurrentFlag(flags[0]) // vietnam flag
            setOtherFlag(flags[1])
        } else {
            setCurrentFlag(flags[1]) // korean flag
            setOtherFlag(flags[0])
        }
    }, [language])


    const [showOtherCountries, setShowOtherCountries] = useState(false)

    const arrow = useRef(null);

    const toggleShowOtherFlag = () => {
        setShowOtherCountries(!showOtherCountries)
        arrow.current.classList.add('turning-arrow');

        setTimeout(() => {
            arrow.current.classList.remove('turning-arrow');
            // arrow.current.classList.add('fa-chevron-up')
        }, 300)
    }



    useEffect(() => {
        setLanguage(currentFlag.language)
    }, [showOtherCountries])

    const handleChooseFlag = () => {
        setCurrentFlag(otherFlag)
        setOtherFlag(currentFlag)
        // setShowOtherFlag(false)
    }

    return (
        <div className="navbar">
            <a className="logo-container">
                <img class='megav-logo' src={VNPTLogo} alt="" />
                <img class='megav-logo' src={MegaVLogo} alt="" />
            </a>
            <div className="nav-item-container ">
                {/* {items.map((item, index) => {
                    return <p className={`nav-item ${checkActiveNavItem(index)}`} onClick={e => handleClickActiveNavItem(index)}>{item}</p>

                })} */}
                <a target="_blank" href='https://apps.apple.com/vn/app/megav/id1611528414?l=vi'>
                    <img className='download' src={DownloadApple} alt="" />

                </a>

                <a target="_blank"  href='https://play.google.com/store/apps/details?id=com.epay.ewallet'>
                    <img className='download' src={DownloadGoogle} alt="" />

                </a>
                <div className="language-selector" onClick={toggleShowOtherFlag}>
                    <div className="flag-container">
                        <img src={currentFlag.flag} alt="" className="flag selected" />
                        {showOtherCountries ?
                            <img src={otherFlag.flag} alt="" className="flag other-flag" onClick={handleChooseFlag} />
                            : <> </>
                        }
                    </div>
                    <img ref={arrow} src={Arrow} alt="" className="arrow" />
                    {/* <i ref={arrow} className="fa-solid arrow"></i> */}
                </div>

            </div>
        </div>
    )
}