
export const displayTextByLanguage = {
    vn: {
        hero: {
            text_in_white_box_html: "<p>Bận lên đơn sao đọc được tin nhắn? <br> Hãy để MegaV thông báo tiền về cho bạn</p>",
            button_text: "Website VNPT EPAY",
            button_contact_text: "Trải nghiệm miễn phí",
            text_items: [
                "Thông báo giao dịch đồng thời tới chủ cửa hàng và nhân viên",
                "Miễn phí giao dịch rút tiền trong ngày, tối đa 500 triệu/ 1 giao dịch",
                "Dễ dàng quản lý doanh thu của nhiều Cửa hàng trên một ứng dụng",
                "Đăng ký giải pháp đơn giản, nhanh chóng",
                "Trải nghiệm miễn phí trong một tháng, có  thể hủy dịch vụ bất cứ lúc nào"
            ]
        },
        USP: {
            section_heading: "Tại sao nên sử dụng giải pháp?",
            store_heading: "Cửa hàng quản lý doanh thu hiệu quả",
            store_items: [
                "Quản lý doanh thu bán hàng qua QR code chỉ với một chạm ",
                "Rút tiền hàng nhanh chóng (24/7)",
                "Nhân viên chủ động kiểm tra giao dịch nhận tiền dù không có chủ tại cửa hàng ",
                "Thêm người nhận thông báo đơn giản ngay trên ứng dụng",
                "Không bị rò rỉ thông tin cá nhân như số tài khoản ngân hàng, tên cá nhân"
            ],
            customer_heading: "Khách hàng thanh toán quét mã QR tiện lợi, nhanh chóng",
            customer_items: [
                "Không cần nhập thông tin tài khoản thụ hưởng",
                "Quét QRcode dễ dàng qua các ứng dụng internet banking, ứng dụng ví điện tử có hỗ trợ quét mã VietQR",
                "Khách hàng kiểm tra tên người nhận là tên cửa hàng trước khi thanh toán, đảm  bảo không chuyển nhầm "
            ],
            text_in_red_box_1: "Đăng ký đơn giản",
            text_in_red_box_2: "tiết kiệm chi phí",
            text_items: [
                "Không cần kết nối kỹ thuật và phần mềm bán hàng.",
                "Tạo mã QR theo tên thương hiệu, tên cửa hàng"
            ]
        },

        registrationRequirements: {
            sectionHeading: "Hồ sơ cần có để đăng ký dịch vụ",
            text_items: [
                "Đăng ký kinh doanh, giấy phép kinh doanh",
                "Giấy tờ tùy thân của chủ hộ kinh doanh (CMT,CCCD, Hộ chiếu)",
                "Số điện thoại đăng ký dịch vụ",
                "Tài khoản ngân hàng nhận tiền thanh toán"
            ]
        },
        QnA: {
            sectionHeading: "Câu hỏi thường gặp",
            questions: [
                {
                    html_title: '<p>1. Tôi có thể cài đặt bao nhiêu người sử dụng dịch vụ <span class="dark-red-text">Thông báo QR?</span><p/>',
                    answers: [
                        "Không giới hạn số lượng nhân viên được nhận thông báo khi chủ Cửa hàng thêm nhân viên",
                        ` Chọn biểu tượng "Cài đặt", nhấn "Danh sách người nhận thông báo", nhập số điện thoại của nhân viên để nhận thông báo`
                    ]
                },
                {
                    html_title: `<p>2. Ai có thể sử dụng dịch vụ <span class="dark-red-text">Thông báo QR?</span><p/>`,
                    answers: [
                        "Các cá nhân/ pháp nhân có đăng ký kinh doanh đều có thể đăng ký sử dụng dịch vụ Thông báo QR"
                    ]
                },
                {
                    html_title: `<p>3. Sau thời gian sử dụng miễn phí có ràng buộc về thời gian sử dụng sau đó hay không?<p/>`,
                    answers: [
                        "Không có ràng buộc về thời gian sử dụng sau thời gian miễn phí, khách hàng có thể ngưng sử dụng dịch vụ bất cứ lúc nào.",
                        "Quý khách hàng có thể dùng dịch vụ tới trước ngày thu phí. Tới thời điểm thu phí, nếu khách hàng không dùng hoặc hủy bỏ dịch vụ thì cũng không bị truy thu phí.",
                    ]
                },
            ],
            items: [
                {
                    title: "Phí sử dụng dịch vụ",
                    text_items: [
                        "Miễn phí 1 tháng sử dụng dịch vụ kể từ thời điểm đăng ký dịch vụ",
                        "Sau thời gian sử dụng miễn phí, hệ thống chuyển sang thu phí dịch vụ",
                        "Phí dịch vụ: 220,000đ/ tháng/ 1 mã QR (bao gồm VAT)"
                    ]
                },
                {
                    title: "Phí rút tiền thanh toán",
                    text_items: [
                        "Miễn phí 1 lần rút tiền/ngày",
                        "Tính phí rút tiền từ giao dịch thứ 2 trong ngày: 5,500đ/ giao dịch (bao gồm VAT)"
                    ]
                }
            ]
        },
        pricing: {
            prices: [
                {
                    title: "Phí sử dụng dịch vụ",
                    text_items: [
                        "Miễn phí 1 tháng sử dụng dịch vụ kể từ thời điểm đăng ký dịch vụ",
                        "Sau thời gian sử dụng miễn phí, hệ thống chuyển sang thu phí dịch vụ",
                        "Phí dịch vụ: 220,000đ/ tháng/ 1 mã QR (bao gồm VAT)"
                    ]
                },
                {
                    title: "Phí rút tiền thanh toán",
                    text_items: [
                        "Miễn phí 1 lần rút tiền/ngày ",
                        "Tính phí rút tiền từ giao dịch thứ 2 trong ngày: 5,500đ/ giao dịch ( bao gồm VAT)"
                    ]
                }
            ]
        },
        register: {
            sectionHeading: "Đăng ký trải nghiệm",
            button: "ĐĂNG KÝ NGAY",
            success: "Đăng ký thành công! Chúng tôi sẽ liên hệ bạn trong thời gian sớm nhất.",
            validate: {
                    name: "Vui lòng nhập tên!",
                    phone: "Vui lòng nhập số điện thoại!",                    
                },
            title:{
                name: "Họ & Tên",
                phone: "Số điện thoại",  
                email: "Email",  
                industry: "Ngành hàng",  
            },
            industry:[
                "---Ngành hàng--",
                "Thời trang",
                "Nhà thuốc ",
                "Mỹ phẩm/Spa",
                "Nhà hàng/Quán ăn",
                "Cafe",
                "Bar/Karaoke",
                "Tạp Hoá/Siêu thị minin",
                "Nội thất gia dụng",
                "Hair Salon, Nails, Massage",
                "Vật liệu xây dựng",
                "Mẹ Bé",
                "Sách văn phòng phẩm",
                "Thiết bị điện tử/điện máy",
                "Trung tâm bảo dưỡng, sửa chữa",
                "Khác"
            ]         
        },
        footer: {
            office1: {
                title: "TRỤ SỞ HÀ NỘI",
                address: "Tầng 14, tòa nhà Handico, khu đô thị mới Mễ Trì Hạ, đường Phạm Hùng, quận Nam Từ Liêm, Hà Nội",
                phoneText: "Điện thoại",
                phone: "+8424-39335133",
                faxText: "FAX",
                fax: "+8424-39335166",
            },
            office2: {
                title: "CHI NHÁNH HCM",
                address: "Lầu 6, Tòa nhà Halo Building, số 91A Cao Thắng, Phường 3, Quận 3, Hồ Chí Minh",
                phoneText: "Điện thoại",
                phone: "+8428-35265969",
                faxText: "FAX",
                fax: "+8428-38443786",
            },
            businessLicense: "Giấy phép hoạt động cung ứng dịch vụ trung gian thanh toán số 21/GP-NHNN do Ngân hàng nhà nước Việt Nam cấp ngày 22/01/2016.",
            hotlineText: "Liên hệ kinh doanh",
            hotline: "19006051",
            hotlineText_html:"<p>HOTLINE: <br> <a target='_blank' href='tel:19006051' class='dark-red-text hotline-number'>19006051</a> <span class='normal-case hotline-number'>(phím 2)</span><br><span class='normal-text'>Thời Gian Làm Việc: Thứ 2 đến Thứ 6</span><br><span class='normal-text'>Buổi sáng từ 08:30am – 12:00am</span><br><span class='normal-text'>Buổi chiều từ 01:00pm – 5:30pm</span></p>",
            emailText_html:"<p>EMAIL: <br> <a target='_blank' href='mailTo:support@megav.vn' class='dark-red-text hotline-number'>support@megav.vn</a></p>",
            phone: "19006051"
        }
    },
    kr: {
        hero: {
            text_in_white_box_html: "<p>바쁜시간에는 소리만으로 입금 확인하세요</p>",
            button_text: "Website VNPT EPAY",
            button_contact_text: "무료 사용",
            text_items: [
                "가게 주인과 직원에게 동시에 알림 발송",
                "당일 출금 수수료 면제, 건당 최대 5억동",
                "여러 매장의 매출액 관리도 한 곳에서 편리하게",
                "앱 설치하고, 서비스 신청하면 바로 사용가능",
                "한달간 무료 입니다.  무료로 시작하세요. 언제든지 해지 가능"
            ]
        },
        USP: {
            section_heading: "서비스를 왜 사용할까요?",
            store_heading: "가게는 안전하고 효율적인 QR매출  관리",
            store_items: [
                "클릭한번으로 QR 매출 확인",
                "언제든지 빠르게 출금 가능 (24/7)",
                "가게주인 부재시에도 직원이 실시간 입금확인",
                "알림받을 직원은 앱으로 추가",
                "개인계좌번호와 계좌주 이름  비공개로 안전",
            ],
            customer_heading: "고객이 QR코드를 편리하고 빠르게 결제 가능",
            customer_items: [
                "수취계좌 정보 입력 불필요",
                "모든 모바일뱅킹앱, 전자지갑앱으로 QR 결제 가능",
                "QR 결제시 가게이름 확인 후 결제"
            ],
            text_in_red_box_1: "간편한 신청",
            text_in_red_box_2: "비용 절감",
            text_items: [
                "별도의 개발이나 소프트웨어 불필요",
                "브랜드명, 가게명 이름으로 QR코드 생성 가능"
            ]
        },

        registrationRequirements: {
            sectionHeading: "서비스 신청을 위한 필요 서류",
            text_items: [
                "사업자 등록증",
                "사업자의 주민 서류 ( 신분증, 여권)",
                "서비스 신청 전화번호",
                "출금받을 가게주인 은행 계좌"
            ]
        },
        QnA: {
            sectionHeading: "FAQ",
            questions: [
                {
                    html_title: `<p><span class="dark-red-text">1. QR입금알림</span>는몇명까지 이용가능한가요?<p/>`,
                    answers: [
                        "신청자(매장주인)는 알림서비스를 받을 직원을 무제한으로 등록 가능합니다.",
                        `입금알림 설정 (톱니바퀴아이콘) 화면으로 이동하여, 알림받을 직원의 전화번호를 등록할 수 있습니다.`
                    ]
                },
                {
                    html_title: `<p><span class="dark-red-text">2. QR입금알림 서비스</span>는 누구나 신청할 수 있나요?<p/>`,
                    answers: [
                        "사업자등록증이 있는 개인/법인 누구나 신청가능합니다."
                    ]
                },
                {
                    html_title: `<p>3. 무료 사용 후 의무사용 기간이 있나요 ?<p/>`,
                    answers: [
                        "무료 사용과 관련하여 의무 사용기간은 없으며, 언제든지 사용을 중지할 수 있습니다.",
                        "유료 전환 되기 전날까지 사용하시고,  유료 전환시점이후 사용하지 않거나 해지하시면 사용료는 청구되지 않습니다."
                    ]
                },
            ],
            items: [
                {
                    title: "서비스 수수료",
                    text_items: [
                        "서비스 가입 시점부터 1개월 무료 이용 혜택 제공 ",
                        "무료 이용기간 종료 후,  유료 서비스로 전환됨",
                        "QR코드별 수수료 부과 : 220,000 동/월 (Vat 포함)"
                    ]
                },
                {
                    title: "출금 수수료",
                    text_items: [
                        "1일 1회 출금 수수료 무료 제공 ",
                        "1일 2회차 출금시 수수료 부과:  5,500동/건 (Vat 포함)"
                    ]
                }
            ]
        },
        pricing: {
            prices: [
                {
                    title: "서비스 수수료",
                    text_items: [
                        "서비스 가입 시점부터 1개월 무료 이용 혜택 제공",
                        "무료 이용기간 종료 후,  유료 서비스로 전환됨",
                        "QR코드별 수수료 부과 : 220,000 동/월 (Vat 포함)"
                    ]
                },
                {
                    title: "출금 수수료",
                    text_items: [
                        "1일 1회 출금 수수료 무료 제공 ",
                        "1일 2회차 출금시 수수료 부과:  5,500동/건 (Vat 포함)"
                    ]
                }
            ]
        },
        register: {
            sectionHeading: "상담 신청",
            button: "신정하기",            
            success: "신정 완료! 최대한 빨리 연락드리겠습니다.",
            validate: {
                    name: "이름을 넣으세요",
                    phone: "전화번호를 넣으세요",                    
                },
            title:{                                                                       
                name: "이름",
                phone: "전화번호",  
                email: "Email",  
                industry: "업종",  
            },
            industry:[
                "---업종--",
                "패션",
                "약국",
                "화장품/스파",
                "레스토랑/식사",
                "카페",
                "바/노래방",
                "식료품/슈퍼마켓 미니인",
                "가정용 가구",
                "미용실, 네일, 마사지",
                "건축자재",
                "아기 엄마",
                "문구 서적",
                "전자/전기 장비",
                "유지보수 및 수리 센터",
                "기타"
            ]       
        },
        footer: {
            office1: {
                title: "Hanoi Headquarter",
                address: "14th Floor, Handico Building, Me Tri Ha new urban area, Pham Hung Street, Me Tri Ward, Nam Tu Liem District, Hanoi",
                phoneText: "Tel",
                phone: "+8424-39335133",
                faxText: "Fax",
                fax: "+8424-39335166",
            },
            office2: {
                title: "HCM representative office",
                address: "6th Floor, Halo Building, 91A Cao Thang Street, District 3, Ho Chi Minh City",
                phoneText: "Tel",
                phone: "+8428-35265969",
                faxText: "Fax",
                fax: "+8428-38443786",
            },
            // hotlineText_html:"<p>HOTLINE: <br> <span class='dark-red-text'>19006051</span> <span class='normal-case'>(2번)</span></p>",
            hotlineText_html:"<p>HOTLINE: <br> <a target='_blank' href='tel:19006051' class='dark-red-text hotline-number'>19006051</a> <span class='normal-case hotline-number'>(2번)</span><br><span class='normal-text'>근무 시간: 월요일 ~ 금요일</span><br><span class='normal-text'>08:30 am – 12:00 pm</span><br><span class='normal-text'>01:00pm – 5:30pm</span></p>",
            emailText_html:"<p>EMAIL: <br> <a target='_blank' href='mailTo:support@megav.vn' class='dark-red-text hotline-number'>support@megav.vn</a></p>",

            hotlineText: "가입안내 전화번호",
            phone: "19006051",
            contacts: [
                {
                    title: "Mr Kim Ho Sung (김호성)",
                    phone: "0833184779"
                },
                {
                    title: "Mr Park Hyun Woo (박현우)",
                    phone: "0833230295"
                }
            ],
            businessLicense: "The License for the provision of intermediary payment service no. 21/GP-NHNN issued on January 22,2016 granted by the State Bank of Vietnam"
        }
    },



}


